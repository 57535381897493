import { version } from '$app/environment';
import { PUBLIC_APPLICATION_ENV } from '$env/static/public';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import posthog from 'posthog-js';

posthog.init('phc_mqVpUdWQktcoib3x6WmJFirEiyNEvksNdhJ7SklJ3ut', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
  capture_pageview: false,
  capture_pageleave: false,
  autocapture: PUBLIC_APPLICATION_ENV !== 'local',
  disable_session_recording: true,
  loaded: (ph) => {
    if (PUBLIC_APPLICATION_ENV === 'local') {
      ph.opt_out_capturing(); // opts a user out of event capture
    }
  },
});

Sentry.init({
  enabled: PUBLIC_APPLICATION_ENV !== 'local',
  release: `platform-client@${version}`,
  environment: PUBLIC_APPLICATION_ENV,
  dsn: 'https://f4f51fba71ba1d20955e2cb616dea648@o4506668208226304.ingest.sentry.io/4506668209602560',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    replayIntegration(),
    posthog.sentryIntegration({
      organization: 'atalantech',
      projectId: 4506668209602560,
      severityAllowList: ['error', 'info'], // optional: here is set to handle captureMessage (info) and captureException (error)
    }),
  ],
  tracesSampler: (ctx) => {
    return ctx.name === '/' ? 0.0 : 1.0;
  },
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
