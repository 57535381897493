import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [6,7,8,2,3,4,5];

export const dictionary = {
		"/": [9],
		"/admin": [38,[6]],
		"/admin/clients": [~39,[6]],
		"/admin/clients/[clientId=uuid]": [~40,[6]],
		"/admin/db": [~41,[6]],
		"/admin/initiatives": [~42,[6,7]],
		"/admin/initiatives/new": [44,[6,7]],
		"/admin/initiatives/[initId=uuid]": [~43,[6,7]],
		"/admin/risk-factors": [~45,[6]],
		"/admin/risk-factors/[id=integer]": [~46,[6]],
		"/admin/users": [~47,[6]],
		"/admin/users/[clientId=uuid]": [~48,[6,8]],
		"/admin/users/[clientId=uuid]/new": [~50,[6,8]],
		"/admin/users/[clientId=uuid]/[userId=integer]": [~49,[6,8]],
		"/(auth)/(base)/glossary": [10,[2]],
		"/(auth)/(base)/home": [~11,[2]],
		"/(auth)/(base)/providers": [~12,[2]],
		"/(auth)/regions": [~18,[2]],
		"/(auth)/regions/[regionId]": [~19,[2,3]],
		"/(auth)/regions/[regionId]/[unitId]": [~20,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/glossary": [21,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/initiatives": [~22,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/initiatives/new": [~24,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/initiatives/new/[templateId=uuid]": [~25,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/initiatives/[initiativeId=uuid]": [23,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/tasks": [~26,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/new/[riskId=id]": [~37,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/discuss-with-team": [~27,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/elaborate-barriers": [~28,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/is-valid-risk": [~29,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/meeting-planned": [30,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/meeting-remind": [31,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/plan-team-meeting": [~32,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/recurring-thank-you": [33,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/review-complete": [~34,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/risk-not-applicable": [~35,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/risk-not-controlled": [~36,[2,3,4,5]],
		"/(auth)/(base)/settings": [13,[2]],
		"/(auth)/(base)/settings/filters": [~14,[2]],
		"/(auth)/(base)/settings/filters/new": [~16,[2]],
		"/(auth)/(base)/settings/filters/[filterId]": [~15,[2]],
		"/(auth)/(base)/settings/notifications": [17,[2]],
		"/signin": [51],
		"/signout": [52]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';